/**
 * VS theme by Andrew Lock (https://andrewlock.net)
 * Inspired by Visual Studio syntax coloring
 */

 code[class*="language-"],
 pre[class*="language-"] {
     color: #393A34;
     font-family: "Consolas", "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;
     direction: ltr;
     text-align: left;
     white-space: pre;
     word-spacing: normal;
     word-break: normal;
     font-size: 0.95em;
     line-height: 1.2em;
 
     -moz-tab-size: 4;
     -o-tab-size: 4;
     tab-size: 4;
 
     -webkit-hyphens: none;
     -moz-hyphens: none;
     -ms-hyphens: none;
     hyphens: none;
 }
 
 pre[class*="language-"]::-moz-selection, pre[class*="language-"] ::-moz-selection,
 code[class*="language-"]::-moz-selection, code[class*="language-"] ::-moz-selection {
     background: #C1DEF1;
 }
 
 pre[class*="language-"]::selection, pre[class*="language-"] ::selection,
 code[class*="language-"]::selection, code[class*="language-"] ::selection {
     background: #C1DEF1;
 }
 
 /* Code blocks */
 pre[class*="language-"] {
     padding: 1em;
     margin: .5em 0;
     overflow: auto;
     border: 1px solid #dddddd;
     background-color: white;
 }
 
 /* Inline code */
 :not(pre) > code[class*="language-"] {
     padding: .2em;
     padding-top: 1px; padding-bottom: 1px;
     background: #f8f8f8;
     border: 1px solid #dddddd;
 }
 
 .token.comment,
 .token.prolog,
 .token.doctype,
 .token.cdata {
     color: #008000; font-style: italic;
 }
 
 .token.namespace {
     opacity: .7;
 }
 
 .token.string {
     color: #A31515;
 }
 
 .token.punctuation,
 .token.operator {
     color: #393A34; /* no highlight */
 }
 
 .token.url,
 .token.symbol,
 .token.number,
 .token.boolean,
 .token.variable,
 .token.constant,
 .token.inserted {
     color: #36acaa;
 }
 
 .token.atrule,
 .token.keyword,
 .token.attr-value,
 .language-autohotkey .token.selector,
 .language-json .token.boolean, 
 .language-json .token.number, 
 code[class*="language-css"]{
     color: #0000ff;
 }
 
 .token.function {
     color: #393A34;
 }
 .token.deleted,
 .language-autohotkey .token.tag {
     color: #9a050f;
 }
 
 .token.selector,
 .language-autohotkey .token.keyword {
     color: #00009f;
 }
 
 .token.important,
 .token.bold {
     font-weight: bold;
 }
 
 .token.italic {
     font-style: italic;
 }
 
 .token.class-name,
 .language-json .token.property {
     color: #2B91AF;
 }
 
 .token.tag,
 .token.selector {
     color: #800000;
 }
 
 .token.attr-name,
 .token.property,
 .token.regex,
 .token.entity {
     color: #ff0000;
 }
 
 .token.directive.tag  .tag {
     background: #ffff00;
     color: #393A34;
 }
 
 /* overrides color-values for the Line Numbers plugin
  * http://prismjs.com/plugins/line-numbers/
  */
 .line-numbers .line-numbers-rows {
   border-right-color: #a5a5a5;
 }
 
 .line-numbers-rows > span:before {
   color: #2B91AF;
 }
 
 /* overrides color-values for the Line Highlight plugin
 * http://prismjs.com/plugins/line-highlight/
 */
 .line-highlight {
   background: rgba(193, 222, 241, 0.2);
   background: -webkit-linear-gradient(left, rgba(193, 222, 241, 0.2) 70%, rgba(221, 222, 241, 0));
   background: linear-gradient(to right, rgba(193, 222, 241, 0.2) 70%, rgba(221, 222, 241, 0));
 }