// 
// 
// articles.scss
//
//

article{
  + article{
    margin-top: $spacer*2;
  }
}