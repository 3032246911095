// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

footer {
  background-color: #0090bb;
}

body {
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

nav li.nav-item a.h5 {
  font-weight: 400;
}

.slider-highlight-selected h5 {
  font-weight: 300;
  font-size: 0.9em;}