// 
// 
// headers.scss
//
//

.header-3{
  .bg-white{
    background: rgba(255,255,255,.95) !important;
  }
}