// 
// 
// ie-fixes.scss
//
// Fixes reported bugs associated with Flexbox for IE11 -- Exlucde this file if IE11 support is not required

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
  .card{
    .card-img-overlay{
      top: 0;
    }
    > a{
      flex-shrink: 0;
    }
    &.justify-content-end{
      .card-img-overlay{
        top: auto;
        bottom: 0;
      }
    }
    &:not([class*='col-']){
      width: 100%;
    }
  }
  div[class*='col'][class*='flex']{
    &:not(.flex-column){
      //display: block !important;
    }
  }
  .flex-column{
    > img.flex-grow-1{
      flex-grow: 0 !important;
      flex-shrink: 0 !important;
    }
  }
  .icon{
    flex-shrink: 0 !important;
  }
  .icon{ 
    width: $spacer*2; 
    &.icon-lg{
      width: $spacer*3;
    }
    &.icon-sm{
      width: $spacer*1.5;
    }
  }
  img{
    flex-shrink: 0;
    &.flex-grow-1{
      flex-grow: 0 !important;
    }
  }
  .list-group{
    width: 100%;
  }
  [class*='height-']{
    height: 1px;
  }
  .row{
    width: 100%;
  }
  .text-image-2{
    display: block;
    .bg-white{
      bottom: 0;
      right: 0;
    }
  }
  .video-cover{
    .video-play-icon{
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
  .video-play-icon{
    &:after{
      content: 'Play';
      bottom: 51px;
      right: 5px;
      font-size: .5rem;
    }
  }
}