// 
// 
// triangle-backgrounds.scss
//
//

section{
    position: relative;
}

.bg-triangle{
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: 300px;
    bottom:0;
    left:0;
    background: none !important;
    polygon{
        fill: $body-bg;
    }
    + .container{
        position: relative;
        z-index: 2;
    }
    &.bg-triangle-top-left{
        transform: scaleX(-1) scaleY(-1);
        top: 0;
        bottom: auto;
    }
    &.bg-triangle-top-right{
        transform: scaleY(-1);
        top: 0;
        bottom: auto;
    }
    &.bg-triangle-bottom-left{
        transform: scaleX(-1);
    }
    &.bg-dark{
        polygon{
            fill: theme-color('dark');
        }
    }
    &.bg-secondary{
        polygon{
            fill: theme-color('secondary');
        }
    }
    &.bg-primary{
        polygon{
            fill: theme-color('primary');
        }
    }
    &.bg-white{
        polygon{
            fill: $white;
        }
    }
    &.bg-gradient{
        polygon{
            fill: linear-gradient(90deg, $blue 0%, $indigo 100%);
        }
    }
}