// 
// 
// flickity.scss
//
//

@import "CSS:../../components/plugins/prism";

pre[class*='language-'], code[class*='language-']{
  background: $gray-300;
  color: $headings-color;
  border: none;
}

article{
  pre[class*='language-']{
    margin: 1rem 0;
    border-radius: $border-radius;
  }
}