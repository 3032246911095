// 
// 
// dropdowns.scss
//
// 

.dropdown-divider{
  margin: .375rem 0;
}

.dropdown-header{
  font-weight: 600;
}

.dropdown-item{
  font-weight: 600;
}


