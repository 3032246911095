// 
// 
// utilities.scss
//
//
.o-hidden {
  overflow: hidden;
}

.o-visible {
  overflow: visible;
}

// Opacity
//
// Control an element's opacity using the class .opacity-# element with value of 0 - 100
.opacity-0 {
  opacity: 0;
}

@for $i from 1 through 9 {
  $opacity: ($i / 10);
  .opacity-#{($i*10)} {
    opacity: $opacity;
  }
}

// Spacing
//
// Control an element's vertical (North South) padding with these utilities

section {
  padding: $spacer*3 0;
}

.spacer-y-0 {
  padding-top: 0;
  padding-bottom: 0;
}

@for $i from 1 through 5 {
  .spacer-y-#{$i} {
    padding-top: (($spacer/2)*$i);
    padding-bottom: (($spacer/2)*$i);
  }
}

@include media-breakpoint-up(md) {
  @for $i from 1 through 5 {
    .spacer-y-#{$i} {
      padding-top: $spacer*$i;
      padding-bottom: $spacer*$i;
    }
  }
  .mt-minus-3{
    margin-top: -$spacer;
  }
}

// Height
//
// Control an elements min-height in vh units using the class .height-# with a value of 0 - 90

.height-0 {
  height: 0;
}

[class*='height-']{
  display: flex;
  &:not(.align-items-initial){
    align-items: center;
  }
  &[class*='container']{
    > .row{
      flex-grow: 1;
    }
  }
}

@for $i from 1 through 10 {
  $height: ($i * 10);
  .height-#{$height} {
    min-height: #{$height}vh;
  }
}

@include media-breakpoint-up(md){
  @for $i from 1 through 10 {
    $height: ($i * 10);
    .height-md-#{$height} {
      min-height: #{$height}vh;
    }
  }
}

@include media-breakpoint-up(lg){
  @for $i from 1 through 10 {
    $height: ($i * 10);
    .height-lg-#{$height} {
      min-height: #{$height}vh;
    }
  }
}

// Borders
//

.join-bottom{
  border-radius: $border-radius $border-radius 0 0 ;
}

.join-top{
  border-radius: 0 0 $border-radius $border-radius;
}

@include media-breakpoint-up(lg){
  .join-lg-left{
    border-radius: 0 $border-radius $border-radius 0 !important;
  }
  .join-lg-right{
    border-radius: $border-radius 0 0 $border-radius !important;
  }
}

@include media-breakpoint-up(lg){
  .border-right-lg{
    border-right: $border-width solid $border-color;
  }
}

// Position
//

@include media-breakpoint-up(lg) {
  .position-lg-absolute {
    position: absolute;
  }
}

@include media-breakpoint-up(md) {
  .position-md-absolute {
    position: absolute;
  }
}

// Backgrounds
//

.bg-gradient{
  background: linear-gradient(90deg, $blue 0%, $indigo 100%);   
}

.bg-gradient-2{
  background: linear-gradient(90deg, $pink 0%, $purple 100%);   
}

.bg-gradient-3{
  background: linear-gradient(90deg, $red 0%, $orange 100%);   
}

// Text
//

.text-white{
  p{
    a{
      color: $white;
    }
  }
}

// Overlays
//

.overlay-dark{
  &:after{
    content: '';
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: 0;
    left: 0;
    background: linear-gradient(transparentize(theme-color('dark'),1),theme-color(dark));
  }
  .container{
    position: relative;
    z-index: 1;
  }
  &.overlay-top{
    &:after{
      background: linear-gradient(theme-color(dark),transparentize(theme-color('dark'),1));
      bottom: auto;
      top: 0;
    }
  }
}

// Back to top button
//
a.back-to-top{
  position: absolute;
  right: $spacer;
  opacity: 0;
  transition: opacity $transition-time ease, transform $transition-time ease;
  transform: translateY(30px);
  &.sticky-viewport-bottom{
    bottom: $spacer;
    z-index: $zindex-popover;
    &.position-fixed{
      opacity: 1;
      transform: translateY(0);
    }
  }
} 